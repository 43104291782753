.side-navigation-primary groupui-headline {
    position: static;
    color: var(--groupui-vwag-color-white);
    padding: 24px 24px 64px;
}

.locationMarker {
    height: 30px;
    width: 40px;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #ffffff;
    padding: 5px 10px;
    font-size: 10px;
}

.locationMarker::after {
    content: "";
    position: absolute;
    left: 20px;
    top: 39px;
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 10px solid var(--marker-color);
    clear: both;
}

.locationMarker[data-background-color]::after {
    border-top-color: attr(data-background-color);
}

groupui-action-area-popover-item:hover {
    background-color: var(--groupui-sys-color-action-subtle-hover);
}

/* spin animation for refresh on dashboard */
@keyframes spin-animation {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(359deg);
    }
}
